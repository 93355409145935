import { call, put, type CallEffect, type PutEffect } from 'redux-saga/effects'

import { instance } from '../../../api'
import { type IGetPersonalProfileResponse } from '../../../api/users/usersAPI.types'
import {
  type GetUserProfile,
  type IGetUserProfile
} from './userMiddleware.types'

const getUser = async (): Promise<IGetPersonalProfileResponse> => {
  const result = await instance({
    url: 'api/v1/users',
    method: 'GET'
  })
  return result.data?.data
}

function* getUserSaga(
  action: IGetUserProfile
): Generator<
  | CallEffect<IGetPersonalProfileResponse>
  | PutEffect<{ type: GetUserProfile; payload: unknown }>,
  void,
  unknown
> {
  try {
    const user = yield call(getUser)
    yield put({ type: 'GetUserProfile', payload: user })
  } catch (error) {
    console.log(error, 'error')
  }
}

export default getUserSaga
