import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type RootState } from '../../store'

export interface IBusinessProfileSlice {
  name: string
  description: string
  email: string
  phone_number: string
  street: string
  city: string
  state: string
  country: string
  username: string
  platform: string
  social_media?: object
  delivery_areas?: object
}

const initialState: IBusinessProfileSlice = {
  name: '',
  description: '',
  email: '',
  phone_number: '',
  street: '',
  city: '',
  state: '',
  country: '',
  username: '',
  platform: '',
  social_media: {},
  delivery_areas: {}
}

export const businessProfileSlice = createSlice({
  name: 'businessProfile',
  initialState,
  reducers: {
    updateBusinessProfileFields: (
      state: IBusinessProfileSlice,
      action: PayloadAction<IBusinessProfileSlice>
    ) => {
      const { payload } = action
      state.name = payload.name
      state.description = payload.description
      state.email = payload.email
      state.phone_number = payload.phone_number
      state.street = payload.street
      state.city = payload.city
      state.state = payload.state
      state.street = payload.street
    }
  }
})

const { actions, reducer } = businessProfileSlice

export const { updateBusinessProfileFields } = actions

export const getBusinessProfileFields = (
  state: RootState
): IBusinessProfileSlice => state.businessProfile

export default reducer
