import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IRegisterSlice {
  registerData: Record<string, unknown>
}

const initialState: IRegisterSlice = {
  registerData: {}
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setRegisterData: (
      state: IRegisterSlice,
      action: PayloadAction<Record<string, unknown>>
    ) => {
      const { payload } = action
      state.registerData = payload
    }
  }
})

const { actions, reducer } = registerSlice
export const { setRegisterData } = actions
export default reducer
