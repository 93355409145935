import { type ForkEffect, takeEvery } from 'redux-saga/effects'
import retrieveVirtualAccountSaga from './saga/business/businessMiddleware'
import getUserSaga from './saga/user/userMiddleware'

function* sloudSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery('TriggerRetrieveAccountAction', retrieveVirtualAccountSaga)
  yield takeEvery('TriggerGetUserAction', getUserSaga)
}

export default sloudSaga
