import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IOrderSlice {
  isShowOrderModal: boolean
  activeOrderModal:
    | 'orderCourierModal'
    | 'orderDeclineModal'
    | 'orderProductModal'
    | ''
}

const initialState: IOrderSlice = {
  isShowOrderModal: false,
  activeOrderModal: ''
}

export const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    setIsShowOrderModal: (
      state: IOrderSlice,
      action: PayloadAction<boolean>
    ) => {
      const { payload } = action
      state.isShowOrderModal = payload
    },
    setActiveOrderModal: (
      state: IOrderSlice,
      action: PayloadAction<
        'orderCourierModal' | 'orderDeclineModal' | 'orderProductModal' | ''
      >
    ) => {
      const { payload } = action
      state.activeOrderModal = payload
    }
  }
})

const { actions, reducer } = orderSlice
export const { setIsShowOrderModal, setActiveOrderModal } = actions
export default reducer
