import React from 'react'

import { type IButton } from './Button.types'

import ButtonStyle from './Button.module.scss'

const Button = ({
  className,
  onPress,
  title,
  isLoading,
  disabled,
  type,
  mode,
  icon,
  styles
}: IButton): JSX.Element => {
  return (
    <button
      type={type}
      onClick={onPress}
      disabled={(isLoading ?? false) || disabled}
      className={`${ButtonStyle.button} ${
        ButtonStyle[`button--${mode ?? 'primary'}`]
      } ${className ?? ''}`}
      style={styles}
    >
      {isLoading ?? false ? 'Loading...' : title}
      {Boolean(icon) && <span>{icon}</span>}
    </button>
  )
}

export default Button
