import React from 'react'
import { Toaster } from 'react-hot-toast'
import AppRoutes from './AppRoutes'

import './App.scss'

const App = (): JSX.Element => {
  return (
    <div className="App">
      <AppRoutes />
      <Toaster
        containerStyle={{
          zIndex: 999999999
        }}
        toastOptions={{
          iconTheme: {
            primary: '#F20788',
            secondary: '#FFFFFF'
          },
          style: {
            border: '.1px solid #152441',
            color: '#152441'
          }
        }}
      />
    </div>
  )
}

export default App
