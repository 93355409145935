import { createSlice } from '@reduxjs/toolkit'
import { metricsAPISlice } from '../../../api/metrics/metricsAPISlice'
import { type RootState } from '../../store'
import formatCash from '../../../util/helpers/formatCash'
import { type Aggregate } from '../../../api/metrics/metricsAPI.types'

interface IMetricsSlice {
  productCount: number
  orderCount: number
  deliveredOrders: number
  returnedOrders: number
  totalCategories: number
  activeCustomers: number
  blacklistedCustomers: number
  successful: number
  pending: number
  failed: number
  moneyOut: number
  totalEarnings: string
  inventoryWorth: string
  recentOrders: Aggregate[]
}

const initialState: IMetricsSlice = {
  productCount: 0,
  orderCount: 0,
  deliveredOrders: 0,
  returnedOrders: 0,
  totalCategories: 0,
  activeCustomers: 0,
  blacklistedCustomers: 0,
  successful: 0,
  pending: 0,
  failed: 0,
  moneyOut: 0,
  totalEarnings: '',
  inventoryWorth: '',
  recentOrders: []
}

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      metricsAPISlice.endpoints.fetchStoreMetrics.matchFulfilled,
      (state, { payload }) => {
        state.productCount = payload.data.productCount
        state.orderCount = payload.data.orderCount
        state.deliveredOrders = payload.data.successfulOrders
        state.returnedOrders = payload.data.returnedOrderCount
        state.totalCategories = payload.data.totalCategories
        state.activeCustomers = payload.data.activeCustomerCount
        state.blacklistedCustomers = payload.data.blockedCustomerCount
        state.successful = payload.data.successfulTransactionCount
        state.pending = payload.data.pendingTransactionCount
        state.failed = payload.data.failedTransactionCount
        state.moneyOut = payload.data.totalWalletPayout
        const totalEarnings = formatCash(
          '₦',
          payload.data.totalEarnings
        ) as string
        const productWorth = formatCash(
          '₦',
          payload.data.productWorth
        ) as string
        state.totalEarnings = totalEarnings
        state.inventoryWorth = productWorth
      }
    )
  }
})

const { reducer } = metricsSlice
export const getMetricsData = (state: RootState): IMetricsSlice =>
  state.metricsSlice
export default reducer
