import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IDashboardHeaderSlice {
  pageHeader: string
  pageSubHeader: string
}

const initialState: IDashboardHeaderSlice = {
  pageHeader: '',
  pageSubHeader: ''
}

export const dashboardHeaderSlice = createSlice({
  name: 'dashboardHeaderSlice',
  initialState,
  reducers: {
    setPageHeader: (
      state: IDashboardHeaderSlice,
      action: PayloadAction<string>
    ) => {
      const { payload } = action
      state.pageHeader = payload
    },
    setPageSubHeader: (
      state: IDashboardHeaderSlice,
      action: PayloadAction<string>
    ) => {
      const { payload } = action
      state.pageSubHeader = payload
    }
  }
})

const { actions, reducer } = dashboardHeaderSlice
export const { setPageHeader, setPageSubHeader } = actions
export default reducer
