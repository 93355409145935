import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ICustomer } from '../../../api/customer/customerAPI.types'

interface ICustomerSlice {
  allCustomers: ICustomer[]
  blockedCustomers: ICustomer[]
  unblockedCustomers: ICustomer[]
  totalCounts: ITotalCount
}

interface ITotalCount {
  allCustomer: number
  active: number
  blocked: number
}

const initialState: ICustomerSlice = {
  allCustomers: [],
  blockedCustomers: [],
  unblockedCustomers: [],
  totalCounts: {
    allCustomer: 0,
    active: 0,
    blocked: 0
  }
}

export const customerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setAllCustomers: (
      state: ICustomerSlice,
      action: PayloadAction<ICustomer[]>
    ) => {
      const { payload } = action
      state.allCustomers = payload
    },
    setBlockedCustomers: (
      state: ICustomerSlice,
      action: PayloadAction<ICustomer[]>
    ) => {
      const { payload } = action
      state.blockedCustomers = payload
    },
    setUnblockedCustomers: (
      state: ICustomerSlice,
      action: PayloadAction<ICustomer[]>
    ) => {
      const { payload } = action
      state.unblockedCustomers = payload
    },
    setTotalCount: (
      state: ICustomerSlice,
      action: PayloadAction<Partial<ITotalCount>>
    ) => {
      const { payload } = action
      state.totalCounts = { ...state.totalCounts, ...payload }
    }
  }
})

const { actions, reducer } = customerSlice
export const {
  setAllCustomers,
  setBlockedCustomers,
  setUnblockedCustomers,
  setTotalCount
} = actions
export default reducer
