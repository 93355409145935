import { type PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'
import { type IVirtualAccount } from '../../../api/business/businessAPI.types'
import {
  type TriggerRetrieveVirtualAccount,
  type RetrieveVirtualAccount
} from '../../saga/business/BusinessMiddleware.types'
import { type RootState } from '../../store'

interface IBusinessSlice {
  virtualAccount: Partial<IVirtualAccount>
}

const RetrieveAccountAction = createAction<
  IVirtualAccount,
  RetrieveVirtualAccount
>('RetrieveVirtualAccount')

const TriggerRetrieveAccountAction = createAction<
  IVirtualAccount,
  TriggerRetrieveVirtualAccount
>('TriggerRetrieveVirtualAccount')

const initialState: IBusinessSlice = {
  virtualAccount: {}
}

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setVirtualAccount: (
      state: IBusinessSlice,
      action: PayloadAction<Partial<IVirtualAccount>>
    ) => {
      const { payload } = action
      state.virtualAccount = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(RetrieveAccountAction, (state, action) => {
        state.virtualAccount = action.payload
      })
      .addCase(TriggerRetrieveAccountAction, state => {
        return state
      })
  }
})

const { actions, reducer } = businessSlice
export const { setVirtualAccount } = actions
export const getVirtualAccount = (state: RootState): Partial<IVirtualAccount> =>
  state.business.virtualAccount
export default reducer
