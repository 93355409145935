import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SuspenseLoader from './components/Loader/SuspenseLoader'
import AuthenticatedRoutes from './components/AuthenticatedRoutes'

const SignIn = React.lazy(async () => await import('./screens/SignIn'))
const SignUp = React.lazy(async () => await import('./screens/SignUp'))
const VerifyEmail = React.lazy(
  async () => await import('./screens/VerifyEmail')
)
const ResetPassword = React.lazy(
  async () => await import('./screens/ResetPassword')
)
const ForgotPassword = React.lazy(
  async () => await import('./screens/ForgotPassword')
)
const Dashboard = React.lazy(async () => await import('./screens/Dashboard'))
const NotFound = React.lazy(async () => await import('./screens/NotFound'))

const AppRoutes = (): JSX.Element => {
  return (
    <React.Suspense fallback={<SuspenseLoader />}>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route element={<AuthenticatedRoutes />}>
          <Route path="/dashboard/*" element={<Dashboard />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Suspense>
  )
}
export default AppRoutes
