import { createApi, type BaseQueryFn } from '@reduxjs/toolkit/query/react'
import Axios, { type AxiosError, type AxiosRequestConfig } from 'axios'
import { getItem, setItem } from '../util/helpers/localStorage'
import {
  refreshToken,
  token,
  IsLoggedInConstant,
  TokenExpiryTimeStampConstant
} from '../util/constants/storage'

export const instance = Axios.create({
  baseURL: process.env.REACT_APP_SLOUD_BASE_URL,
  timeout: 10000,
  timeoutErrorMessage: 'Request timeout'
})

instance.interceptors.request.use(config => {
  const TOKEN = getItem(token)
  const REFRESH_TOKEN = getItem(refreshToken)

  if (TOKEN !== null) {
    config.headers.Authorization = TOKEN
    config.headers.RefreshAuthorization = REFRESH_TOKEN
  }

  return config
})

instance.interceptors.response.use(
  response => response,
  async error => {
    const { response } = error

    if (
      window.location.pathname.includes('/dashboard') &&
      response?.status === 401
    ) {
      window.location.href = '/'

      return await Promise.reject(error)
    }
    return await Promise.reject(error)
  }
)

const axiosBaseQuery: BaseQueryFn<
  AxiosRequestConfig,
  unknown,
  {
    message: string
    response?: unknown
  }
> = async ({ url, method, data, params }) => {
  try {
    const result = await instance({
      url,
      method,
      data,
      params
    })

    if (url === 'api/v1/auth/login' || url === 'api/v1/auth/refresh_token') {
      const headers = result?.headers

      setItem(token, headers?.authorization)
      setItem(refreshToken, headers?.refreshauthorization)
      setItem(IsLoggedInConstant, headers?.isloggedin)
      setItem(TokenExpiryTimeStampConstant, headers?.tokenexpirytimestamp)
    }

    return { data: result?.data }
  } catch (axiosError) {
    const error = axiosError as AxiosError

    const errorInfo = {
      message: error.message,
      response: { data: error.response?.data }
    }

    return { error: errorInfo }
  }
}

export const apiService = createApi({
  baseQuery: axiosBaseQuery,
  endpoints: () => ({})
})
