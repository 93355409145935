import { instance } from '../../../api'
import { type IVirtualAccount } from '../../../api/business/businessAPI.types'
import {
  type RetrieveVirtualAccount,
  type IRetrieveVirtualAccountAction
} from './BusinessMiddleware.types'
import { call, put, type CallEffect, type PutEffect } from 'redux-saga/effects'

const retrieveVirtualAccount = async (): Promise<IVirtualAccount> => {
  const result = await instance({
    url: 'api/v1/wallets',
    method: 'GET'
  })
  return result.data?.data
}

function* retrieveVirtualAccountSaga(
  action: IRetrieveVirtualAccountAction
): Generator<
  | CallEffect<IVirtualAccount>
  | PutEffect<{ type: RetrieveVirtualAccount; payload: unknown }>,
  void,
  unknown
> {
  try {
    const virtualAccount = yield call(retrieveVirtualAccount)
    yield put({ type: 'RetrieveVirtualAccount', payload: virtualAccount })
  } catch (error) {
    console.log(error, 'error')
  }
}

export default retrieveVirtualAccountSaga
