import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IOverviewSlice {
  isTriggerSetupCall: boolean
}

const initialState: IOverviewSlice = {
  isTriggerSetupCall: false
}

export const overviewSlice = createSlice({
  name: 'overviewSlice',
  initialState,
  reducers: {
    setIsTriggerSetupCall: (
      state: IOverviewSlice,
      action: PayloadAction<boolean>
    ) => {
      const { payload } = action
      state.isTriggerSetupCall = payload
    }
  }
})

const { actions, reducer } = overviewSlice
export const { setIsTriggerSetupCall } = actions
export default reducer
