import React from 'react'
import PrimaryLoader from '../../assets/images/sloud-primary-loader.gif'
import SecondaryLoader from '../../assets/images/sloud-secondary-loader.gif'
import WhiteLoader from '../../assets/images/sloud-white-loader.gif'

interface ILoader {
  type: 'primary' | 'secondary' | 'white'
}

const Loader = ({ type }: ILoader): JSX.Element => (
  <img
    style={{
      objectFit: 'contain',
      height: '50px'
    }}
    src={
      type === 'primary'
        ? PrimaryLoader
        : type === 'secondary'
        ? SecondaryLoader
        : WhiteLoader
    }
    alt="Loader"
  />
)

export default Loader
