import { createSlice, createAction, type PayloadAction } from '@reduxjs/toolkit'
import { type IUserPersonalProfile } from '../../../api/users/usersAPI.types'
import type {
  GetUserProfile,
  TriggerGetUserProfile
} from '../../saga/user/userMiddleware.types'

interface IUserSlice {
  userData: Partial<IUserPersonalProfile>
}

const GetUserAction = createAction<IUserPersonalProfile, GetUserProfile>(
  'GetUserProfile'
)

const TriggerGetUserProfileAction = createAction<
  IUserPersonalProfile,
  TriggerGetUserProfile
>('TriggerGetUserProfile')

const initialState: IUserSlice = {
  userData: {}
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserData: (
      state: IUserSlice,
      action: PayloadAction<IUserPersonalProfile>
    ) => {
      const { payload } = action
      state.userData = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(GetUserAction, (state, action) => {
        state.userData = action.payload
      })
      .addCase(TriggerGetUserProfileAction, state => {
        return state
      })
  }
})

const { actions, reducer } = userSlice
export const { setUserData } = actions
export default reducer
