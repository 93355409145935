import React from 'react'
import { Navigate, Outlet } from 'react-router'
import { IsLoggedInConstant } from '../../util/constants/storage'
import { getItem } from '../../util/helpers/localStorage'

const AuthenticatedRoutes = (): JSX.Element => {
  const isLoggedIn = Boolean(getItem(IsLoggedInConstant))

  return isLoggedIn ? <Outlet /> : <Navigate to={'/'} />
}

export default AuthenticatedRoutes
