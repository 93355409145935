import separateNumbers from './separateNumbers'

const formatCash = (
  currencyIcon: string,
  numInp = 0,
  showDecimals = true
): string | number => {
  if (Number.isNaN(Number(numInp))) {
    return numInp
  }
  const num = showDecimals
    ? Number(numInp ?? 0).toFixed(2)
    : Math.floor(Number(numInp ?? 0))
  const str = num?.toString()?.replace(/,/g, '')
  const currency = `${currencyIcon ?? ''}`
  return currency + separateNumbers(str, ',')
}

export const hiddenCharacter = (
  char: string,
  hiddenFormatChar = '*'
): string => {
  const repeatedChar = hiddenFormatChar.repeat(char.length)
  if (repeatedChar.length < 3) return hiddenFormatChar.repeat(3)
  return repeatedChar
}

export default formatCash
