import RocketIcon from '../../assets/svg_components/RocketIcon'

import ErrorBoundaryStyles from './ErrorBoundary.module.scss'
import Button from '../Button'

const ErrorBoundary = (): JSX.Element => {
  return (
    <div className={ErrorBoundaryStyles.errorBoundary}>
      <div className={ErrorBoundaryStyles.errorBoundary__content}>
        <div className={ErrorBoundaryStyles.errorBoundary__image}>
          <RocketIcon />
        </div>

        <h2
          className={`${ErrorBoundaryStyles.errorBoundary__title} ${
            ErrorBoundaryStyles[`errorBoundary__title--main`]
          }`}
        >
          Oops!!
        </h2>

        <h2 className={ErrorBoundaryStyles.errorBoundary__title}>
          Something went wrong!
        </h2>

        <p className={ErrorBoundaryStyles.errorBoundary__subtext}>
          We are sorry but it seems that an unexpected error has occured
        </p>

        <Button
          title="Try again"
          className={ErrorBoundaryStyles.errorBoundary__button}
          onPress={() => {
            window.location.reload()
          }}
          type="button"
          mode="secondary"
        />
      </div>
    </div>
  )
}

export default ErrorBoundary
